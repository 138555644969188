@import '_var.scss';
@import '_mixin.scss';
@import '_sprites.scss';
@import '_base.scss';

.only_web {
	display: none;
}

.btn {
	display: inline-block;
	max-width: 387px;
	width: 100%; 
	font-size: 37px;
	height: 76px;
	padding: 0 10px;
	border-radius: 4px;
	border: none;
	color: $clr-base;
	font-weight: 700;
	text-transform: uppercase;
	cursor: pointer;
	transition: all 0.7s ease-out;
	transform: scale(1);
	&-red {
		background: $clr-red;
		box-shadow: 0 4px 0 0 rgba(156, 8, 8, 1);
	}
	&-ylw {
		background: $clr-yellow;
		box-shadow: 0 4px 0 0 #c89d15;
	}
	&:hover {
		transform: scale(1.1);

	}
}

body {
	font-family: $Roboto;
	font-size: $base-font;
	color: $clr-base;
	line-height: 1.2;
}

.container {
	max-width: 1000px;
	margin: 0 auto;
}
//------------------------------------------------------
//					BLOCK 1
//------------------------------------------------------

.nav {
	position: relative;
	&-inner {
		width: 100%;
	}
	.logo-wrap {
		padding: 25px 5px;
	}
	.logo-wrap,
	&-iconMenu {
		display: inline-block;
		vertical-align: middle;	
	}
	&-iconMenu {
		height: 42px;
		width: 42px;
		cursor: pointer;
		position: absolute;
		right: 10px;
		top: 18px;
		span {
			display: block;
			height: 2px;
			width: 100%;
			background: #fff;
			margin: 10px 0;
		}
	}
	&-menu {
		display: none;
		&_visible {
			display: block;
			width: 100%;
			position: absolute;
			top: 0;
		}
		ul {			
			background: rgba(0,0,0,.9);
			width: 100%;
			text-transform: uppercase;
			position: absolute;
			top: 90px;
			z-index: 1;
			li {
				text-align: center;
				border-bottom: 1px solid #282828;
				a {
					display: block;
					padding: 25px 0;
					text-transform: uppercase;
					font-size: 11px;
					color: #fff;
					text-decoration: none;
					line-height: 1;	
				}	
				&:hover {
					border-bottom-color: $clr-red;					
					a {
						color: $clr-red;
					}	
				}
			}
		}
	}
}

.block1 {
	background: #fff url('../img/header_bg.png') no-repeat center/cover;
	height: auto;
	padding-bottom: 25px;
	&-inner {
		padding: 35px 10px 0;
		display: block;
		margin: 0 auto;
		text-align: center;
		h3 {
			font-size: 32px;
			font-style: italic;
			letter-spacing: -1px;
			text-transform: uppercase;
		}
		h4 {
			text-transform: uppercase;
			font-size: 18px;
			margin-top: 10px;
		}
		.head-quote {
			display: block;
			max-width: 704px;
			position: relative;
			margin: 30px auto;
			font-size: $mid-font;
			&_tit {
				color: $clr-red;
				text-transform: uppercase;
				font-weight: 300;
				margin-bottom: 25px;
			}
			p {
				color: $clr-yellow;
				line-height: 1.2;
			}
		}
		.video-box {
			p {
				margin: 20px 0;
			}
		}
		.prod-wrap {
			position: relative;
			display: inline-block;

		}
		.price {
			display: none;
		}
		
	}
}


//------------------------------------------------------
//					BLOCK 2
//------------------------------------------------------

.block2 {
	background: #fff;
	padding: 40px 0;
	color: $clr-grey;
	font-weight: 600;
	&-inner {
		display: block;
		margin: 0 auto;
		text-align: center;
		padding: 0 10px;
	}
	h3 {
		font-size: 20px;
		text-transform: uppercase;
		font-weight: 600;
		margin: 25px 0;
		span {
			display: block;
			font-size: 34px;
			color: $clr-red;
		}
	}
	&-frame {
		background: $clr-yellow;
		padding: 5px;
		max-width: 800px;
		margin: 0 auto;
		position: relative;
		z-index: 5;
		&_inner {
			background: #fff;
			padding: 10px 5px;
		}
	}
	.plus-list {
		display: block;
		width: 100%; 
		&_item {
			display: block;
			width: 250px;
			margin: 0 auto 25px;
			padding-top: 100px;
			position: relative;
			&:before {
				@include pseudo;
				@extend %block2-icon;
				top: 10px;
				left: 50%;
			}
			&:nth-of-type(1){
				&:before {
					@extend %block2-icon_1;
    				margin-left: -35px;
				}
			}
			&:nth-of-type(2){
				&:before {
					@extend %block2-icon_2;
    				margin-left: -28px;
				}
			}
			&:nth-of-type(3){
				&:before {
					@extend %block2-icon_3;
					margin-left: -55px;
				}
			}
			&:nth-of-type(4){
				&:before {
					@extend %block2-icon_4;
					margin-left: -40px;
				}
			}
		}
	}
}

//------------------------------------------------------
//					BLOCK 3
//------------------------------------------------------

.block3 {
	background: transparent url('../img/results_bg.png') no-repeat center/cover;
	margin-top: -190px;
	position: relative;
	z-index: 4;
	padding: 210px 0 50px;
	&-inner {
		display: block;
		margin: 0 auto;
		padding: 0 10px;
		h3 {
			color: $clr-yellow;
			font-size: 20px;
			text-transform: uppercase;
			font-weight: 600;
			text-align: center;
			margin-bottom: 25px;
			span {
				font-size: 34px;
				display: block;
			}
		}
	}
	.result-list {
		margin-left: 80px;
		&_item {
			margin-bottom: 25px;
			position: relative;
			min-height: 60px;
			p {
				line-height: 1.4;
				font-size: 14px;
				font-weight: 500;
			}
			hr {
				width: 88px;
				height: 4px;
				background: #e2b320;
				margin-bottom: 10px;
			}
			&:before {
				@include pseudo;
				@extend %block3-icon;
				top: 0px;
			}
			&:nth-of-type(1){
				&:before {
					@extend %block3-icon_1;
    				left: -80px;
				}
			}
			&:nth-of-type(2){
				&:before {
					@extend %block3-icon_2;
    				left: -83px;
				}
			}
		}
		&:last-of-type {
			.result-list {
				&_item {
					&:nth-of-type(1){
						&:before {
							@extend %block3-icon_3;
							left: -80px;
						}
					}
					&:nth-of-type(2){
						&:before {
							@extend %block3-icon_4;
							left: -84px;
						}
					}
				}
			}
			
		}
	}
	.btn-wrap {
		margin: 0 auto;
		text-align: center;
	}
}

//------------------------------------------------------
//					BLOCK 4
//------------------------------------------------------

.block4 {
	padding-bottom: 50px;
	margin: 0 auto;	
	position: relative;
	z-index: 3;
	&-inner {
		padding: 0 10px;
		text-transform: uppercase;
		font-weight: 700;
		max-width: 480px;
		margin: 0 auto;
		h5 {
			font-size: 29px;
			color: #be0026;
			margin: 15px 0;
		}
		h6 {
			font-size: 30px;
			color: #be0026;
			margin: 15px 0;
		}
		p {
			color: #6d6d6d;
			font-size: 20px;
			margin: 15px 0;
		}
		.price {
			color: $clr-yellow;
			font-size: 18px;
			.new-price {
				display: inline;
				color: $clr-grey;
				font-size: 38px;
			}
		}
		.btn-wrap {
			margin: 25px auto;
			text-align: center;
			border: 1px dashed #ccc;
			padding: 20px ;
			border-radius: 5px;
		}
	}
}
//------------------------------------------------------
//					BLOCK 5
//------------------------------------------------------

.block5 {
	background: url(../img/about_bg.png) no-repeat center/cover;
	padding: 50px 0 150px;
	position: relative;
	z-index: 2;
	margin-top: -20px;
	&-inner {
		padding: 0 10px;
		margin: 0 auto;
		h3 {
			color: $clr-yellow;
			font-size: 20px;
			text-transform: uppercase;
			font-weight: 600;
			text-align: center;
			margin-bottom: 25px;
		}
		hr {
			background: $clr-base;
			height: 4px;
			width: 150px;
			margin-bottom: 10px;
		}
		p {
			margin-bottom: 20px;
		}
		.logo {
			display: block;
			margin: 40px auto 0; 

		}
	}
}
//------------------------------------------------------
//					BLOCK 6
//------------------------------------------------------

.block6 {
	background: #f4f4f4;
	padding: 190px 0 40px;
	position: relative;
	z-index: 1;
	margin-top: -190px;
	&-inner {
		color: $clr-grey;
		max-width: 480px;
		margin: 0 auto;
		padding: 0 10px;
		h3 {
			font-size: 34px;
			text-transform: uppercase;			
			font-weight: 700;
			text-align: center;
			margin-bottom: 25px;
		}
	}
	.comments {		
		&-list {
			padding-left: 65px;
			position: relative;
			&_item {
				margin-bottom: 35px;
				position: relative;
				.author {
					&-ava {
						display: block;
						width: 55px;
						height: 55px;
						background: url('../img/nofoto.jpg') no-repeat center/ contain;
						border-radius: 50%;
						position: absolute;
						left: -63px;
						top: 0;
					}
					&-name {
						display: block;
						color: $clr-red;
						text-transform: uppercase;
						font-weight: 700;
						margin: 10px 0;
					}
				}
				hr {
					width: 80px;
					height: 3px;
					background: $clr-yellow;
					margin: 20px 0 0;
				}
				p {
					font-size: 12px;
					font-weight: 700;
					line-height: 1.4;
				}
			}
		}
		
		
	}
}
//------------------------------------------------------
//					BLOCK 7
//------------------------------------------------------

.block7 {
	background: #f4f4f4;
	&-inner {
		display: block;
		margin: 0 auto;
		.gellary {
			max-width: 768px;
			width: 100%;
			margin: 0 auto;
			padding: 0 10px;
			&:after {
				content: "";
				display: block;
				clear: both;
			}
			&-item {
				display: inline-block;
				width: 50%;
				height: 70px;
				overflow: hidden;
				float: left;
				img {
					width: 100%;
				}
				&:first-child {
					width: 100%;
				}
			}
		}
	}
}
//------------------------------------------------------
//					BLOCK 8
//------------------------------------------------------

.block8 {
	padding: 50px 0;
	&-inner {
		text-align: center;
		h3 {
			display: block;
    		font-size: 34px;
    		color: #be0026;
    		margin: 20px auto;
    		text-align: center;
		}
		.htu-inner {
			position: relative;
			display: block;
			max-width: 768px;
			margin: 0 auto;
			padding: 15px;
			border-radius: 5px;
			counter-reset: n1;
			background: #b7b7b7; /* Old browsers */
			background: -moz-linear-gradient(45deg, #b7b7b7 1%, #cccccc 50%, #adadad 50%, #9b9b9b 50%, #c9c9c9 50%, #bfbfbf 50%, #8e8e8e 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(45deg, #b7b7b7 1%,#cccccc 50%,#adadad 50%,#9b9b9b 50%,#c9c9c9 50%,#bfbfbf 50%,#8e8e8e 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(45deg, #b7b7b7 1%,#cccccc 50%,#adadad 50%,#9b9b9b 50%,#c9c9c9 50%,#bfbfbf 50%,#8e8e8e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b7b7b7', endColorstr='#8e8e8e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
			
		}
		.htu{
			position: relative;
			&:after {
				@include pseudo;
				background: url('../img/download_price_arrow.png') no-repeat 0 0;
				width: 43px;
				height: 46px;
				bottom: -58px;
				left: 50%;
				transform: translateX(-50%);
			}
			&-list {
				display: block;
				text-align: center;
				&_item {
					display: block;
					width: 100%;
					vertical-align: top;
					padding-top: 90px;
					position: relative;
					margin-bottom: 20px;
					p {
						text-transform: uppercase;
						font-size: 18px;
						font-weight: 700;
						padding: 0 15px;
					}
					&:nth-of-type(1), &:nth-of-type(2){
						.num {
							&:before {
								width: 0;
								border: 35px solid transparent;
								border-top: 35px solid #f2da09;
								transform: rotateZ(55deg);
								top: 0px;
								right: 0px;
								border-radius: 50%;
							}
						}					
					}
					&:nth-of-type(1){
						.num {
							&:after {
								width: 0;
								border: 35px solid transparent;
								border-top: 35px solid #f2da09;
								transform: rotateZ(100deg);
								top: 0;
								right: 0;
								border-radius: 50%;
							}
						}
						
					}
					&:nth-of-type(2){
						.num {
							&:after {
								width: 0;
								border: 35px solid transparent;
								border-top: 35px solid #f2da09;
								border-left: 35px solid #f2da09;
								transform: rotateZ(191deg);
								top: 0px;
	    						right: 0px;
								border-radius: 50%;   
							}
						}
						
					}
					&:nth-of-type(3),
					&:nth-of-type(4), 
					&:nth-of-type(5){
						.num {
							&:before {
								width: 70px;
								height: 70px;
								border-radius: 50%;
								background: #f2da09;
								top: 0;
								left: 0;
							}
						}
					}
				}			
				.num {
					position: absolute;
					display: table;
					width: 70px;
					height: 70px;
					border-radius: 50%;
					background: rgba(0,0,0,.1);
					padding: 3px;
					top: 15px;
					left: 50%;
					margin-left: -35px;
					font {
						display: table-cell;
						vertical-align: middle;
						text-align: center;
						background: #7b7b7b;
						border-radius: 50%;
						position: relative;
						z-index: 2;
						&:before {
							counter-increment: n1;
							content: counter(n1);					
							font-size: 40px;
							font-weight: 700;
							color: #fff;
						}
					}
					&:before, &:after {
						@include pseudo;
					}
					
				}
			}
		}
		.btn-wrap {
			margin: 100px 10px 0;
		}
		.btn {
			color: $clr-grey;	
		}
	}
}
//------------------------------------------------------
//					BLOCK 9
//------------------------------------------------------

.block9 {
	background: url(../img/catalog_bg.jpg) no-repeat center/ cover;
	padding: 50px 0;
	position: relative;
	z-index: 2;
	&-inner {
		display: block;
		margin: 0 auto;
		text-align: center;
		max-width: 300px;
		h3 {
			color: #e2b320;
			font-size: 34px;
			text-transform: uppercase;
			font-weight: 600;
			text-align: center;
			margin-bottom: 25px;
		}
	}
	.catalog {
		&-img {
			margin-bottom: -5px;
			position: relative;
			.bf_item, .af_item  {
				position: absolute;
				font-size: 12px;
				text-shadow: 1px 1px #000, -1px 1px #000, 1px -1px #000, -1px -1px #000;					
			}
			.bf_item {
				left: 10px;
				top: 5px;
			}
			.af_item {
				right: 10px;
				top: 5px;
			}
			img {
				max-height: 100%;
			}
		}
		&-item {
			display: inline-block;
			vertical-align: middle;
			max-width: 300px;
			width: 100%;
			min-height: 200px;
			border: 5px solid #aaa;
			border-radius: 4px;
			position: relative;
			.bf-af-wrap {
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
	}
	.bx-pager {
		display: block;
		margin: 15px 0;
		&-item {
			display: inline-block;
			width: 20px;
			height: 20px;
			margin: 0 5px;
			font-size: 0;
			cursor: pointer;
			a {
				display: block;
				width: 20px;
				height: 20px;
				background: #aaa;
				border-radius: 50%;
				&.active {
					background: #e2b320;
				}
			}
		}
	}
	.container {
		position: relative;
		&:after {
			@include pseudo;
			width: 40px;
			height: 40px;
			background: #000;
			bottom: -68px;
			left: 50%;
			margin-left: -20px;
			transform: rotate(45deg);
			border-radius: 5px;
		}
	}
}
//------------------------------------------------------
//					BLOCK 10
//------------------------------------------------------

.block10 {
	background: #f0eeee;
	padding: 100px 0;
	margin-top: -50px;
	position: relative;
	z-index: 1;
	&-inner {
		display: block;
		max-width: 100%;
		margin: 0 auto;
		padding: 0 10px;
		h3 {
			color: #000;
			font-size: 20px;
			text-transform: uppercase;
			font-weight: 600;
			text-align: center;
			margin-bottom: 25px;
		}
	}
	.ques-answ {
		font-size: 14px;
		color: $clr-com;
		line-height: 1.4;
		font-weight: 500;
		&_item {
			margin-bottom: 20px;
			.text-block {
				border-radius: 5px;
				padding: 8px;
			}
		}
	}
	.auth-name {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 10px;
	}
	.ques {
		.text-block {
			background: #dae1e2;			
		}
	}
	.answ {
		margin-left: 25px;
		.text-block {
			background: #f5e9c2;
		}	
		.auth-name {
			color: #000;
		}
	}
}
//------------------------------------------------------
//					BLOCK 11
//------------------------------------------------------

.block11 {
	background: url(../img/catalog_bg.jpg) no-repeat center/ cover;
	padding: 50px 0 40px;
	position: relative;
	z-index: 2;
	&-inner {
		padding: 0 10px ;
		display: block;
		margin: 0 auto;
		text-align: center;
		h3 {			
			font-size: 20px;
			text-transform: uppercase;
			font-weight: 600;
			text-align: center;
			margin-bottom: 25px;
			span {
				font-size: 30px;
				display: block;
				color: #ff0008;
			}
		}
	}
	.form-wrap {
		display: block;
		max-width: 350px;
		margin: 25px auto;
		background:  rgba(146, 146, 146, 0.4);
		border: 1px solid #ccc;
		border-top: 2px solid #ccc;
		border-radius: 10px;
		padding: 30px;
	}
	.form-tit {
		text-transform: uppercase;
		margin-bottom: 15px;
	}
	.timer-wrap {
		box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.39);
    	padding: 30px 15px;
	}	
	.price {
		&-inner {
			display: inline-block;
			vertical-align: middle;
			width: 49%;
			font-size: 30px;
			text-transform: uppercase;
			font-weight: 400;
			margin: 15px 0;
		}
		.old-price {
			color: #ff0008;
			text-decoration: line-through;
		}
	}
	.form {
		width: 100%;
		position: relative;
		.inp {
			width: 100%;
			height: 40px;
			margin-bottom: 10px;
			border-radius: 5px;
			text-indent: 15px;
			padding: 0;
		}
		select.inp {
			text-indent: 0;
			padding-left: 15px;
			background: #fff url('../img/customselect.png') no-repeat 95% 18px;
			-webkit-appearance: none;
       			-moz-appearance: none;
            		appearance: none;
		}
		select.inp {
			&::-ms-expand {
    			display: none;
			}
		}
		.btn {
			position: absolute;
			left: 0;
			font-size: 30px;
			height: 60px;
			bottom: -65px;
		}
	}

}