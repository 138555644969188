@import '_var.scss';

@mixin logo {
	font-size: $font-logo;
	font-family: $OpenSans;
	color: $BLUE;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: -5px;
}
	

@mixin headline ($color, $size, $weight ) {
	color: $color;
	font-size: $size;
	font-weight: $weight;
}
	

@mixin pseudo ($display: block, $pos: absolute, $content: "") {
	content: $content;
	display: $display;
	position: $pos;
}


@mixin cf{
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}
	
		

@mixin placeholder {
	&:-moz-placeholder 	{
		@content 
	};
	&::-moz-placeholder {
	 	@content 
	};
	&:-ms-input-placeholder {
		@content 
	};
	&::-webkit-input-placeholder {
		@content
	};
}
	


@mixin bg-gradient($start-colour, $end-colour) {
	background-color: $start-colour;
    background-image: -webkit-gradient(linear, left top, left bottom, from($start-colour), to($end-colour));
    background-image: -webkit-linear-gradient(top, $start-colour, $end-colour);
    background-image:    -moz-linear-gradient(top, $start-colour, $end-colour);
    background-image:     -ms-linear-gradient(top, $start-colour, $end-colour);
    background-image:      -o-linear-gradient(top, $start-colour, $end-colour);
    background-image:         linear-gradient(top, $start-colour, $end-colour);
    filter:            progid:DXImageTransform.Microsoft.gradient(start-colourStr='#{$start-colour}', end-colourStr='#{$end-colour}');

}

@mixin price_old ($fz: $sub-tit, $color: #000, $fw: 700, $ff: $RobCond){
	font-size: $fz ;
	color: $color ;
	font-weight: $fw ;
	font-family: $ff ;
}
	
@mixin price_new ($fz: $price-new, $color: $RED, $fw: 700, $ff: $RobCond){
	font-size: $fz ;
	color: $color ;
	font-weight: $fw ;
	font-family: $ff ;
}
	
@mixin price_ship ($fz: $small-tit, $color: #000, $fw: 400, $ff: $RobCond){
	font-size: $fz ;
	color: $color ;
	font-weight: $fw ;
	font-family: $ff ;
}
	
@mixin price_total ($fz: $big-tit, $color: $BLUE , $fw: 700, $ff: $RobCond){
	font-size: $fz ;
	color: $color ;
	font-weight: $fw ;
	font-family: $ff; 
}
	