.block2 .plus-list_item:before {
  background-image: url("../img/block2-icon.png");
  background-repeat: no-repeat;
  display: block;
}

.block2 .plus-list_item:nth-of-type(1):before {
  width: 70px;
  height: 85px;
  background-position: -5px -5px;
}

.block2 .plus-list_item:nth-of-type(2):before {
  width: 51px;
  height: 84px;
  background-position: -5px -100px;
}

.block2 .plus-list_item:nth-of-type(3):before {
  width: 111px;
  height: 80px;
  background-position: -5px -194px;
}

.block2 .plus-list_item:nth-of-type(4):before {
  width: 80px;
  height: 80px;
  background-position: -5px -284px;
}

.block3 .result-list_item:before {
  background-image: url("../img/block3-icon.png");
  background-repeat: no-repeat;
  display: block;
}

.block3 .result-list_item:nth-of-type(1):before {
  width: 66px;
  height: 65px;
  background-position: -5px -5px;
}

.block3 .result-list_item:nth-of-type(2):before {
  width: 74px;
  height: 71px;
  background-position: -5px -80px;
}

.block3 .result-list:last-of-type .result-list_item:nth-of-type(1):before {
  width: 61px;
  height: 64px;
  background-position: -5px -161px;
}

.block3 .result-list:last-of-type .result-list_item:nth-of-type(2):before {
  width: 76px;
  height: 61px;
  background-position: -5px -235px;
}

html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

/* Sections
   ========================================================================== */
* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

ul, li {
  list-style-type: none;
}

.hedden {
  display: none;
}

img {
  border-style: none;
  max-width: 100%;
}

/* Forms
   ========================================================================== */
hr,
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  border: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

.only_web {
  display: none;
}

.btn {
  display: inline-block;
  max-width: 387px;
  width: 100%;
  font-size: 37px;
  height: 76px;
  padding: 0 10px;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.7s ease-out;
  transform: scale(1);
}

.btn-red {
  background: #be0026;
  box-shadow: 0 4px 0 0 #9c0808;
}

.btn-ylw {
  background: #e2b320;
  box-shadow: 0 4px 0 0 #c89d15;
}

.btn:hover {
  transform: scale(1.1);
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
}

.nav {
  position: relative;
}

.nav-inner {
  width: 100%;
}

.nav .logo-wrap {
  padding: 25px 5px;
}

.nav .logo-wrap, .nav-iconMenu {
  display: inline-block;
  vertical-align: middle;
}

.nav-iconMenu {
  height: 42px;
  width: 42px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 18px;
}

.nav-iconMenu span {
  display: block;
  height: 2px;
  width: 100%;
  background: #fff;
  margin: 10px 0;
}

.nav-menu {
  display: none;
}

.nav-menu_visible {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
}

.nav-menu ul {
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  text-transform: uppercase;
  position: absolute;
  top: 90px;
  z-index: 1;
}

.nav-menu ul li {
  text-align: center;
  border-bottom: 1px solid #282828;
}

.nav-menu ul li a {
  display: block;
  padding: 25px 0;
  text-transform: uppercase;
  font-size: 11px;
  color: #fff;
  text-decoration: none;
  line-height: 1;
}

.nav-menu ul li:hover {
  border-bottom-color: #be0026;
}

.nav-menu ul li:hover a {
  color: #be0026;
}

.block1 {
  background: #fff url("../img/header_bg.png") no-repeat center/cover;
  height: auto;
  padding-bottom: 25px;
}

.block1-inner {
  padding: 35px 10px 0;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.block1-inner h3 {
  font-size: 32px;
  font-style: italic;
  letter-spacing: -1px;
  text-transform: uppercase;
}

.block1-inner h4 {
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 10px;
}

.block1-inner .head-quote {
  display: block;
  max-width: 704px;
  position: relative;
  margin: 30px auto;
  font-size: 18px;
}

.block1-inner .head-quote_tit {
  color: #be0026;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 25px;
}

.block1-inner .head-quote p {
  color: #e2b320;
  line-height: 1.2;
}

.block1-inner .video-box p {
  margin: 20px 0;
}

.block1-inner .prod-wrap {
  position: relative;
  display: inline-block;
}

.block1-inner .price {
  display: none;
}

.block2 {
  background: #fff;
  padding: 40px 0;
  color: #424242;
  font-weight: 600;
}

.block2-inner {
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 0 10px;
}

.block2 h3 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 25px 0;
}

.block2 h3 span {
  display: block;
  font-size: 34px;
  color: #be0026;
}

.block2-frame {
  background: #e2b320;
  padding: 5px;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

.block2-frame_inner {
  background: #fff;
  padding: 10px 5px;
}

.block2 .plus-list {
  display: block;
  width: 100%;
}

.block2 .plus-list_item {
  display: block;
  width: 250px;
  margin: 0 auto 25px;
  padding-top: 100px;
  position: relative;
}

.block2 .plus-list_item:before {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: 50%;
}

.block2 .plus-list_item:nth-of-type(1):before {
  margin-left: -35px;
}

.block2 .plus-list_item:nth-of-type(2):before {
  margin-left: -28px;
}

.block2 .plus-list_item:nth-of-type(3):before {
  margin-left: -55px;
}

.block2 .plus-list_item:nth-of-type(4):before {
  margin-left: -40px;
}

.block3 {
  background: transparent url("../img/results_bg.png") no-repeat center/cover;
  margin-top: -190px;
  position: relative;
  z-index: 4;
  padding: 210px 0 50px;
}

.block3-inner {
  display: block;
  margin: 0 auto;
  padding: 0 10px;
}

.block3-inner h3 {
  color: #e2b320;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-bottom: 25px;
}

.block3-inner h3 span {
  font-size: 34px;
  display: block;
}

.block3 .result-list {
  margin-left: 80px;
}

.block3 .result-list_item {
  margin-bottom: 25px;
  position: relative;
  min-height: 60px;
}

.block3 .result-list_item p {
  line-height: 1.4;
  font-size: 14px;
  font-weight: 500;
}

.block3 .result-list_item hr {
  width: 88px;
  height: 4px;
  background: #e2b320;
  margin-bottom: 10px;
}

.block3 .result-list_item:before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
}

.block3 .result-list_item:nth-of-type(1):before {
  left: -80px;
}

.block3 .result-list_item:nth-of-type(2):before {
  left: -83px;
}

.block3 .result-list:last-of-type .result-list_item:nth-of-type(1):before {
  left: -80px;
}

.block3 .result-list:last-of-type .result-list_item:nth-of-type(2):before {
  left: -84px;
}

.block3 .btn-wrap {
  margin: 0 auto;
  text-align: center;
}

.block4 {
  padding-bottom: 50px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}

.block4-inner {
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 700;
  max-width: 480px;
  margin: 0 auto;
}

.block4-inner h5 {
  font-size: 29px;
  color: #be0026;
  margin: 15px 0;
}

.block4-inner h6 {
  font-size: 30px;
  color: #be0026;
  margin: 15px 0;
}

.block4-inner p {
  color: #6d6d6d;
  font-size: 20px;
  margin: 15px 0;
}

.block4-inner .price {
  color: #e2b320;
  font-size: 18px;
}

.block4-inner .price .new-price {
  display: inline;
  color: #424242;
  font-size: 38px;
}

.block4-inner .btn-wrap {
  margin: 25px auto;
  text-align: center;
  border: 1px dashed #ccc;
  padding: 20px;
  border-radius: 5px;
}

.block5 {
  background: url(../img/about_bg.png) no-repeat center/cover;
  padding: 50px 0 150px;
  position: relative;
  z-index: 2;
  margin-top: -20px;
}

.block5-inner {
  padding: 0 10px;
  margin: 0 auto;
}

.block5-inner h3 {
  color: #e2b320;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-bottom: 25px;
}

.block5-inner hr {
  background: #fff;
  height: 4px;
  width: 150px;
  margin-bottom: 10px;
}

.block5-inner p {
  margin-bottom: 20px;
}

.block5-inner .logo {
  display: block;
  margin: 40px auto 0;
}

.block6 {
  background: #f4f4f4;
  padding: 190px 0 40px;
  position: relative;
  z-index: 1;
  margin-top: -190px;
}

.block6-inner {
  color: #424242;
  max-width: 480px;
  margin: 0 auto;
  padding: 0 10px;
}

.block6-inner h3 {
  font-size: 34px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
}

.block6 .comments-list {
  padding-left: 65px;
  position: relative;
}

.block6 .comments-list_item {
  margin-bottom: 35px;
  position: relative;
}

.block6 .comments-list_item .author-ava {
  display: block;
  width: 55px;
  height: 55px;
  background: url("../img/nofoto.jpg") no-repeat center/contain;
  border-radius: 50%;
  position: absolute;
  left: -63px;
  top: 0;
}

.block6 .comments-list_item .author-name {
  display: block;
  color: #be0026;
  text-transform: uppercase;
  font-weight: 700;
  margin: 10px 0;
}

.block6 .comments-list_item hr {
  width: 80px;
  height: 3px;
  background: #e2b320;
  margin: 20px 0 0;
}

.block6 .comments-list_item p {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
}

.block7 {
  background: #f4f4f4;
}

.block7-inner {
  display: block;
  margin: 0 auto;
}

.block7-inner .gellary {
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

.block7-inner .gellary:after {
  content: "";
  display: block;
  clear: both;
}

.block7-inner .gellary-item {
  display: inline-block;
  width: 50%;
  height: 70px;
  overflow: hidden;
  float: left;
}

.block7-inner .gellary-item img {
  width: 100%;
}

.block7-inner .gellary-item:first-child {
  width: 100%;
}

.block8 {
  padding: 50px 0;
}

.block8-inner {
  text-align: center;
}

.block8-inner h3 {
  display: block;
  font-size: 34px;
  color: #be0026;
  margin: 20px auto;
  text-align: center;
}

.block8-inner .htu-inner {
  position: relative;
  display: block;
  max-width: 768px;
  margin: 0 auto;
  padding: 15px;
  border-radius: 5px;
  counter-reset: n1;
  background: #b7b7b7;
  /* Old browsers */
  background: -moz-linear-gradient(45deg, #b7b7b7 1%, #cccccc 50%, #adadad 50%, #9b9b9b 50%, #c9c9c9 50%, #bfbfbf 50%, #8e8e8e 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #b7b7b7 1%, #cccccc 50%, #adadad 50%, #9b9b9b 50%, #c9c9c9 50%, #bfbfbf 50%, #8e8e8e 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #b7b7b7 1%, #cccccc 50%, #adadad 50%, #9b9b9b 50%, #c9c9c9 50%, #bfbfbf 50%, #8e8e8e 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b7b7b7', endColorstr='#8e8e8e',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}

.block8-inner .htu {
  position: relative;
}

.block8-inner .htu:after {
  content: "";
  display: block;
  position: absolute;
  background: url("../img/download_price_arrow.png") no-repeat 0 0;
  width: 43px;
  height: 46px;
  bottom: -58px;
  left: 50%;
  transform: translateX(-50%);
}

.block8-inner .htu-list {
  display: block;
  text-align: center;
}

.block8-inner .htu-list_item {
  display: block;
  width: 100%;
  vertical-align: top;
  padding-top: 90px;
  position: relative;
  margin-bottom: 20px;
}

.block8-inner .htu-list_item p {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  padding: 0 15px;
}

.block8-inner .htu-list_item:nth-of-type(1) .num:before, .block8-inner .htu-list_item:nth-of-type(2) .num:before {
  width: 0;
  border: 35px solid transparent;
  border-top: 35px solid #f2da09;
  transform: rotateZ(55deg);
  top: 0px;
  right: 0px;
  border-radius: 50%;
}

.block8-inner .htu-list_item:nth-of-type(1) .num:after {
  width: 0;
  border: 35px solid transparent;
  border-top: 35px solid #f2da09;
  transform: rotateZ(100deg);
  top: 0;
  right: 0;
  border-radius: 50%;
}

.block8-inner .htu-list_item:nth-of-type(2) .num:after {
  width: 0;
  border: 35px solid transparent;
  border-top: 35px solid #f2da09;
  border-left: 35px solid #f2da09;
  transform: rotateZ(191deg);
  top: 0px;
  right: 0px;
  border-radius: 50%;
}

.block8-inner .htu-list_item:nth-of-type(3) .num:before, .block8-inner .htu-list_item:nth-of-type(4) .num:before, .block8-inner .htu-list_item:nth-of-type(5) .num:before {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #f2da09;
  top: 0;
  left: 0;
}

.block8-inner .htu-list .num {
  position: absolute;
  display: table;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  padding: 3px;
  top: 15px;
  left: 50%;
  margin-left: -35px;
}

.block8-inner .htu-list .num font {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background: #7b7b7b;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}

.block8-inner .htu-list .num font:before {
  counter-increment: n1;
  content: counter(n1);
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}

.block8-inner .htu-list .num:before, .block8-inner .htu-list .num:after {
  content: "";
  display: block;
  position: absolute;
}

.block8-inner .btn-wrap {
  margin: 100px 10px 0;
}

.block8-inner .btn {
  color: #424242;
}

.block9 {
  background: url(../img/catalog_bg.jpg) no-repeat center/cover;
  padding: 50px 0;
  position: relative;
  z-index: 2;
}

.block9-inner {
  display: block;
  margin: 0 auto;
  text-align: center;
  max-width: 300px;
}

.block9-inner h3 {
  color: #e2b320;
  font-size: 34px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-bottom: 25px;
}

.block9 .catalog-img {
  margin-bottom: -5px;
  position: relative;
}

.block9 .catalog-img .bf_item, .block9 .catalog-img .af_item {
  position: absolute;
  font-size: 12px;
  text-shadow: 1px 1px #000, -1px 1px #000, 1px -1px #000, -1px -1px #000;
}

.block9 .catalog-img .bf_item {
  left: 10px;
  top: 5px;
}

.block9 .catalog-img .af_item {
  right: 10px;
  top: 5px;
}

.block9 .catalog-img img {
  max-height: 100%;
}

.block9 .catalog-item {
  display: inline-block;
  vertical-align: middle;
  max-width: 300px;
  width: 100%;
  min-height: 200px;
  border: 5px solid #aaa;
  border-radius: 4px;
  position: relative;
}

.block9 .catalog-item .bf-af-wrap {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.block9 .bx-pager {
  display: block;
  margin: 15px 0;
}

.block9 .bx-pager-item {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  font-size: 0;
  cursor: pointer;
}

.block9 .bx-pager-item a {
  display: block;
  width: 20px;
  height: 20px;
  background: #aaa;
  border-radius: 50%;
}

.block9 .bx-pager-item a.active {
  background: #e2b320;
}

.block9 .container {
  position: relative;
}

.block9 .container:after {
  content: "";
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  background: #000;
  bottom: -68px;
  left: 50%;
  margin-left: -20px;
  transform: rotate(45deg);
  border-radius: 5px;
}

.block10 {
  background: #f0eeee;
  padding: 100px 0;
  margin-top: -50px;
  position: relative;
  z-index: 1;
}

.block10-inner {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

.block10-inner h3 {
  color: #000;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-bottom: 25px;
}

.block10 .ques-answ {
  font-size: 14px;
  color: #54657e;
  line-height: 1.4;
  font-weight: 500;
}

.block10 .ques-answ_item {
  margin-bottom: 20px;
}

.block10 .ques-answ_item .text-block {
  border-radius: 5px;
  padding: 8px;
}

.block10 .auth-name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.block10 .ques .text-block {
  background: #dae1e2;
}

.block10 .answ {
  margin-left: 25px;
}

.block10 .answ .text-block {
  background: #f5e9c2;
}

.block10 .answ .auth-name {
  color: #000;
}

.block11 {
  background: url(../img/catalog_bg.jpg) no-repeat center/cover;
  padding: 50px 0 40px;
  position: relative;
  z-index: 2;
}

.block11-inner {
  padding: 0 10px;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.block11-inner h3 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-bottom: 25px;
}

.block11-inner h3 span {
  font-size: 30px;
  display: block;
  color: #ff0008;
}

.block11 .form-wrap {
  display: block;
  max-width: 350px;
  margin: 25px auto;
  background: rgba(146, 146, 146, 0.4);
  border: 1px solid #ccc;
  border-top: 2px solid #ccc;
  border-radius: 10px;
  padding: 30px;
}

.block11 .form-tit {
  text-transform: uppercase;
  margin-bottom: 15px;
}

.block11 .timer-wrap {
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.39);
  padding: 30px 15px;
}

.block11 .price-inner {
  display: inline-block;
  vertical-align: middle;
  width: 49%;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 400;
  margin: 15px 0;
}

.block11 .price .old-price {
  color: #ff0008;
  text-decoration: line-through;
}

.block11 .form {
  width: 100%;
  position: relative;
}

.block11 .form .inp {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
  text-indent: 15px;
  padding: 0;
}

.block11 .form select.inp {
  text-indent: 0;
  padding-left: 15px;
  background: #fff url("../img/customselect.png") no-repeat 95% 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.block11 .form select.inp::-ms-expand {
  display: none;
}

.block11 .form .btn {
  position: absolute;
  left: 0;
  font-size: 30px;
  height: 60px;
  bottom: -65px;
}
