html {
  line-height: 1.15; 
  -ms-text-size-adjust: 100%; 
  -webkit-text-size-adjust: 100%; 
}

/* Sections
   ========================================================================== */

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}
 
ul, li {
  list-style-type: none;
}

.hedden {
  display: none;
}


img {
  border-style: none;
  max-width: 100%;
}


/* Forms
   ========================================================================== */

hr,
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif; 
  font-size: 100%;   
  line-height: 1.15; 
  margin: 0; 
  border: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}


/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}


select {   
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
select::-ms-expand {
    display: none;
}

