// fonts size

$main-titl-font: 60px;
$mid-titl-font: 30px;
$base-font: 16px;
$mid-font:18px;
$small-font: 13px;

// fonts family

$Roboto: 'Roboto', sans-serif;

// colors

$clr-base: #fff;
$clr-red: #be0026;
$clr-yellow: #e2b320;
$clr-grey: #424242;
$clr-com: #54657e;
