// --------------------------------------
//              block2 
//---------------------------------------
    
    %block2-icon {
        background-image: url('../img/block2-icon.png');
        background-repeat: no-repeat;
        display: block;
    }

    %block2-icon_1 {
        width: 70px;
        height: 85px;
        background-position: -5px -5px;
    }

    %block2-icon_2 {
        width: 51px;
        height: 84px;
        background-position: -5px -100px;
    }

    %block2-icon_3 {
        width: 111px;
        height: 80px;
        background-position: -5px -194px;
    }

    %block2-icon_4 {
        width: 80px;
        height: 80px;
        background-position: -5px -284px;
    }

// --------------------------------------
//              block3
//--------------------------------------- 

    %block3-icon {
        background-image: url('../img/block3-icon.png');
        background-repeat: no-repeat;
        display: block;
    }

    %block3-icon_1 {
        width: 66px;
        height: 65px;
        background-position: -5px -5px;
    }

    %block3-icon_2 {
        width: 74px;
        height: 71px;
        background-position: -5px -80px;
    }

    %block3-icon_3 {
        width: 61px;
        height: 64px;
        background-position: -5px -161px;
    }

    %block3-icon_4 {
        width: 76px;
        height: 61px;
        background-position: -5px -235px;
    }


// --------------------------------------
//              HTW-icon
//--------------------------------------- 
